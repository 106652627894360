<script>
export default defineComponent({
  setup() {
    const breadcrumbStore = useBreadCrumb()
    const breadcrumbItems = computed(() => breadcrumbStore.breadCrumbItems)
    const pageTitle = ref('')

    const breadcrumbSubscription = breadcrumbStore.$subscribe(() => {
      const lastItems = breadcrumbItems.value[breadcrumbItems.value.length - 1]
      pageTitle.value = lastItems.title || lastItems.label
    })

    onBeforeUnmount(() => {
      breadcrumbSubscription()
    })

    return {
      breadcrumbItems,
      pageTitle,
    }
  },
})
</script>

<template>
  <nav class="flex flex-col ml-8 my-8" aria-label="Breadcrumb">
    <h1 class="text-xl font-bold">
      {{ pageTitle }}
    </h1>
    <ul class="inline-flex items-center space-x-1 md:space-x-3 py-2">
      <ClientOnly>
        <li v-for="(item, index) in breadcrumbItems" :key="index">
          <nuxt-link
            v-if="!item.active"
            :to="item.path"
            class="inline-flex items-center text-sm font-sm text-[#ACACAC] hover:text-blue-600"
          >
            {{ item.label }}
          </nuxt-link>
          <span v-else class="text-sm font-sm text-[#4782FC]">{{
            item.label
          }}</span>
        </li>
      </ClientOnly>
    </ul>
  </nav>
</template>

<style scoped>
li {
  &:after {
    content: "/";
    padding-left: 15px;
    color:rgb(172 172 172 / 1);
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
}
</style>
